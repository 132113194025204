import { useContext } from 'react';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';

import { GlobalTopicContext } from 'core/components/GlobalTopicProvider';

import { StickyContainer } from 'core/components/Shapka';
import ContentBackground from 'core/components/ContentBackground';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Desktop, Mobile } from 'core/components/breakpoint';

import Scooter from 'site/components/Scooter';
import Shapka from 'site/components/Shapka';
import BreakpointSwitcher from 'site/components/BreakpointSwitcher';
import PageWrapper from 'site/components/Wrappers/PageWrapper';
import TelegramBanner from 'site/components/TelegramBanner';

import {
  Billboard,
  Shtorka,
  Fullscreen as DesktopFullscreen,
} from 'site/components/Ads/desktop';

import {
  TopBanner,
  Rich,
  Fullscreen as MobileFullscreen,
} from 'site/components/Ads/mobile';


export default function SiteLayout({ children }) {
  const topic = useContext(GlobalTopicContext);
  const isPremiumTopic = topic?.attributes?.is_premium;

  return (
    <>
      <StickyContainer>
        <ContentBackground>
          <Layout>
            <Header>
              <Shapka isSecondShapkaForPremium={isPremiumTopic} />
            </Header>
            <Content>
              {!isPremiumTopic && (
                <BreakpointSwitcher
                  mobile={<TopBanner />}
                  desktop={<Billboard />}
                />
              )}
              <PageWrapper>
                {children}
              </PageWrapper>
            </Content>
            <Footer>
              <Scooter />
            </Footer>
            <TelegramBanner />
          </Layout>
        </ContentBackground>
      </StickyContainer>
      {!isPremiumTopic && (
        <>
          <Desktop>
            <Shtorka />
            <DesktopFullscreen />
          </Desktop>
          <Mobile>
            <Rich />
            <MobileFullscreen />
          </Mobile>
        </>
      )}
    </>
  );
}

SiteLayout.propTypes = {
  content: modelPropTypes(topicAttributes),
};
